import Layout from '../components/Layout';
import { Content } from '../components/home/Content';
import { Gallery } from '../components/home/Gallery';

const Home = () => {
    return <Layout>
        {/* page content */}
        <div className="page-title">
            <div className="main-title special-font">Invitation</div>
        </div>
        <div className='home-container'>
            <Gallery></Gallery>
            <Content></Content>
        </div>
        <div className="title invitation-button" style={{ marginTop: '30px' }}>
            <button onClick={() => { window.open("https://jinshuju.net/f/E6VIOf", "_blank") }}>填写宾客详细信息</button>
        </div>
        {/* page content end*/}
    </Layout>
}
export default Home;