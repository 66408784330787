import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./gallery.css"
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export const Gallery = () => {
    return (
        <div className="container-item gallery">

            <Swiper className="mySwiper"
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                >
                <SwiperSlide>
                    <div className="picture-box" style={{ backgroundImage: "url('/gallery/app/1.jpg')" }}>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="picture-box" style={{ backgroundImage: "url('/gallery/app/2.jpg')" }}>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="picture-box" style={{ backgroundImage: "url('/gallery/app/3.jpg')" }}>
                    </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                    <div className="picture-box" style={{ backgroundImage: "url('/gallery/app/4.jpg')" }}>
                    </div>
                </SwiperSlide> */}
            </Swiper>
            <div className="gallery-title">
                <div className="main-title special-font">毛宜杰 & 罗维佳</div>
                <div className="sub-title">我们诚挚邀请您莅临我们的婚礼，共同见证这一特殊时刻。</div>
            </div>
        </div>
    )
}